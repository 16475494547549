import React from 'react'
import './Burger.css'
import MenuOpenIcon from '@material-ui/icons/MenuOpen';
import CloseIcon from '@material-ui/icons/Close';
function Burger({ setShow, show }) {
    return (
        <div onClick={() => setShow(!show)} className="line">
            { !show && <MenuOpenIcon style={{ fontSize: '36px' }} />}
            { show && <CloseIcon style={{ fontSize: '36px' }} />}
        </div>
    )
}

export default Burger
