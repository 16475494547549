import React from 'react'
import './Skills.css'
import html from '../images/html.png'
import js from '../images/js.png'
import firebase from '../images/firebase.png'
import css from '../images/css.jpg'
import react from '../images/react.png'

function Skills() {
    return (
        <div className="skills" id="skills">

            <div className="whatIKnow">
                <p style={{ fontWeight: '700', fontSize: '45px' }}>Skills</p>
                <p style={{ color: 'darkgray', fontWeight: '900', marginBottom: '40px' }}>__what i know__</p>

            </div>
            <div className="skillContainer">
                <div className="leftSkill">
                    <p className="skillHeading" >My skills & experiences</p>
                    <p className="leftSkillPara" style={{ fontWeight: '500', color: 'black', fontSize: '18px', marginBottom: '30px' }}>Highly motivated,self starting developer seeking to launch a career building web applications and services.Familiar with deployment and development process for many web-based technologies</p>
                </div>
                <div className="rightSkill1">
                    <div className="right3">
                        <div className="programmingSkills">
                            <img src={html} alt="" />
                            <p style={{ color: '#E44D26' }}>HTML</p>
                        </div>
                        <div className="programmingSkills">
                            <img src={css} alt="" />
                            <p style={{ color: '#379AD6' }}>CSS</p>
                        </div>
                        <div className="programmingSkills">
                            <img src={js} alt="" />
                            <p style={{ color: '#FFCC00' }}>JavaScript</p>
                        </div>
                    </div>
                    <div className="right3">
                        <div className="programmingSkills">
                            <img src={react} alt="" />
                            <p style={{ color: '#00D6FD' }}>React</p>
                        </div>
                        <div className="programmingSkills">
                            <img src={firebase} alt="" />
                            <p style={{ color: '#FFA000' }}>Firebase</p>
                        </div>
                    </div>

                </div>

            </div>

        </div>
    )
}

export default Skills
