import React from 'react'
import './SideDrawer.css'
import { Link } from 'react-scroll'
import { motion } from 'framer-motion'
function SideDrawer({ setShow }) {
    return (
        <motion.div className="sideDrawer"
            initial={{ x: '-100vw' }}
            animate={{ x: 0 }}
            transition={{ delay: .3, ease: 'easeIn' }}
        >
            <Link className="sideLink"
                to="home"
                onClick={() => setShow(false)}
                smooth={true}
                duration={1000}

            >Home</Link>
            <Link className="sideLink"
                to="about"
                onClick={() => setShow(false)}
                smooth={true}
                duration={1000}

            >About</Link>
            <Link className="sideLink"
                to="services"
                smooth={true}
                onClick={() => setShow(false)}
                duration={1000}

            >Services</Link>
            <Link className="sideLink"
                to="skills"
                smooth={true}
                onClick={() => setShow(false)}
                duration={1000}

            >Skills</Link>
            <Link className="sideLink"
                to="projects"
                smooth={true}
                onClick={() => setShow(false)}
                duration={1000}

            >Projects</Link>
            <Link className="sideLink"
                to="contact"
                smooth={true}
                onClick={() => setShow(false)}
                duration={1000}

            >Contact me</Link>
        </motion.div>
    )
}

export default SideDrawer
