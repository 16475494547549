import React, { useEffect, useState } from 'react'
import './Header.css'
import { Link } from 'react-scroll'

function Header() {

    const [scrolling, setScrolling] = useState(false)
    const [scrollingTop, setScrollingTop] = useState(10);

    useEffect(() => {
        function onScroll() {


            let currentPosition = (window.pageYOffset)
            const headerScroll = document.querySelector('.Header')
            if (currentPosition > scrollingTop) {
                headerScroll.classList.remove('Header1')
                setScrolling(false)


            }
            else {
                headerScroll.classList.add('Header1')
                setScrolling(true)
            }
            setScrollingTop(currentPosition > 10 ? currentPosition : 10)
        }
        window.addEventListener('scroll', onScroll)
        return () => window.removeEventListener("scroll", onScroll);
    }, [scrollingTop])
    return (
        <div className="Header">
            <div className="left">
                PortFo<span>Lio</span></div>
            <div className="right">

                <Link className={scrolling ? "link" : "link1"}
                    to="home"
                    smooth={true}
                    duration={1000}

                >Home</Link>
                <Link className={scrolling ? "link" : "link1"}
                    to="about"
                    smooth={true}
                    duration={1000}

                >About</Link>
                <Link className={scrolling ? "link" : "link1"}
                    to="services"
                    smooth={true}
                    duration={1000}

                >Services</Link>
                <Link className={scrolling ? "link" : "link1"}
                    to="skills"
                    smooth={true}
                    duration={1000}

                >Skills</Link>
                <Link className={scrolling ? "link" : "link1"}
                    to="projects"
                    smooth={true}
                    duration={1000}

                >Projects</Link>
                <Link className={scrolling ? "link" : "link1"}
                    to="contact"
                    smooth={true}
                    duration={1000}

                >Contact me</Link>
            </div>
        </div>
    )
}

export default Header
