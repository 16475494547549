import React from 'react'
import { Link } from 'react-scroll'
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import './Button.css'

function Button() {
    return (
        <Link className="buttonLink"
            to="home"
            smooth={true}
            duration={1000}
        >
            <ArrowUpwardIcon style={{ color: 'white', fontWeight: 1000 }} />
        </Link>

    )
}

export default Button
