import React from 'react'
import './Projects.css'
import dezone from '../images/dezone.jpg'
import movie from '../images/movie.jpg'
import chat from '../images/chat.jpg'
import degram from '../images/degram.jpg'

function Projects() {
    return (
        <div style={{ paddingTop: '10px', fontWeight: 800, fontSize: '28px' }} className="projectContainer12" id="projects">
            <p className="projPara" style={{ display: 'block', textAlign: 'center', fontSize: '30px' }}>My Projects</p>
            <div className="project">
                <div className="project1s">
                    <a href="https://dezon-d19d3.web.app/" className="project1">
                        <img src={dezone} alt="" />
                        <h2>E-commerce website</h2>
                        <p>This website is created using react and firebase with full functionality of authentication,purchase and checkOut</p>
                    </a>
                    <a href="https://de-app-193b5.web.app/" className="project1">
                        <img src={chat} alt="" />
                        <h2>Real Time chat app</h2>
                        <p>It is a real time time chat website with google authentication</p>
                    </a>
                    <a href="https://degram-app.web.app/" className="project1">
                        <img src={degram} alt="" />
                        <h2>Social website</h2>
                        <p>This is a instagram clone in which user can authenticate,upload photos,comment on a particular post and see his profile</p>
                    </a>
                    <a href="https://movieinfo-1e17d.web.app/" className="project1">
                        <img src={movie} alt="" />
                        <h2>Movie-Info website</h2>
                        <p>This website is created using react,firebase and MovieDb api in which user can search for his favourite movie  </p>
                    </a>


                </div>
            </div>
        </div>
    )
}

export default Projects
