import React from 'react'
import './AboutMe.css'
import my from '../images/my.jpg'
import Typical from 'react-typical'
import { NavLink } from 'react-router-dom'


function AboutMe() {
    return (
        <div className="about" id="about">
            <div className="aboutMe">
                <p>About Me</p>
                <p style={{ color: 'darkgray' }}>____Who I Am____</p>
            </div>
            <div className="dhruvCool" >
                <div className="leftContainer">
                    <img src={my} className="left" />
                </div>

                <div className="right1" >
                    <p className="type">I'm Dhruv and i'm a {`   `}
                        <Typical
                            className="typical"

                            loop={Infinity}
                            wrapper='b'
                            steps={
                                [
                                    'Developer...',
                                    2000,
                                    'FreeLancer...',
                                    2000
                                ]
                            }
                        />

                    </p>
                    <p className="body">
                        I'm a UI Designer and a frontEnd Web Developer from Gurugram,India
                    </p>
                    <p className="intro">
                        I enjoy to take complex problems and turning them into simple and beautiful interface designs.I also love the logic and structure of coding and always strive to write elegant and efficient code in HTML,CSS,JavaScript.
                    </p>
                    <NavLink className="button" to="./resume.pdf" target="__blank" download>Download Resume</NavLink>
                </div>

            </div>

        </div>
    )
}

export default AboutMe
