import React from 'react'
import './Services.css'
import AirplayIcon from '@material-ui/icons/Airplay';
import ImportantDevicesIcon from '@material-ui/icons/ImportantDevices';
import LanguageIcon from '@material-ui/icons/Language';

function Services() {
    return (
        <div id="services" className="serviceContainer">
            <div className="service1">
                <p style={{ fontSize: '30px', fontWeight: 800, marginBottom: '10px' }}>Services</p>
                <p style={{ color: 'darkgray', fontWeight: 'bolder', fontSize: 'small' }} className="provide">_What i provide_ </p>
            </div>
            <div className="service">
                <div className="service2">
                    <AirplayIcon className="air" />
                    <h3 style={{ marginTop: '20px', marginBottom: '20px' }}>Web App Development</h3>
                    <p style={{ fontSize: '16px' }}>Experience in creating web-app using one of the trending technolgies of 2021 react.js which makes your app incredibly fast.</p>
                </div>
                <div className="service2">
                    <ImportantDevicesIcon className="air" />
                    <h3 style={{ marginTop: '20px', marginBottom: '20px' }}>Responsive Designs</h3>
                    <p style={{ fontSize: '16px' }}>Make your app fully responsive for mobile,tablet and desktop using media queries. </p>
                </div>
                <div className="service2">
                    <LanguageIcon className="air" />
                    <h3 style={{ marginTop: '20px', marginBottom: '20px' }}>Backend Development</h3>
                    <p style={{ fontSize: '16px' }}>Can create fully functional app using google firebase with free hosting.</p>
                </div>

            </div>


        </div>
    )
}

export default Services
