import './App.css';
import React, { useState } from 'react'
import AboutMe from './Components/AboutMe';
import Header from './Components/Header';
import Home from './Components/Home'
import Button from './Components/Button'
import Services from './Components/Services';
import Skills from './Components/Skills';
import Projects from './Components/Projects';
import Contact from './Components/Contact';
import Burger from './Components/Burger';
import SideDrawer from './Components/SideDrawer';

function App() {
  const [show, setShow] = useState(false);
  return (
    <div className="App">
      <Header />
      <Burger show={show} setShow={setShow} />
      <Button />
      <Home />
      <AboutMe />
      <Services />
      <Skills />
      <Projects />
      <Contact />
      {show && <SideDrawer setShow={setShow} />}

    </div>
  );
}

export default App;
