import React from 'react'
import './Contact.css'
import PersonIcon from '@material-ui/icons/Person';
import HomeIcon from '@material-ui/icons/Home';
import EmailIcon from '@material-ui/icons/Email';
import emailjs from 'emailjs-com'
import PhoneIcon from '@material-ui/icons/Phone';
function Contact() {
    function sendEmail(e) {
        e.preventDefault();

        emailjs.sendForm('service_uu2okz7', 'template_eueks1q', e.target,
            'user_nqpu0YWC2n1aH17CHWhfe')

            .then((result) => {
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            });
        e.target.reset();
    }
    return (
        <div id="contact" className="contactContainer">
            <div className="touch">
                <p style={{ fontWeight: 'bolder', fontSize: '40px', color: 'black' }}>Contact me</p>
                <p style={{ fontWeight: 'bold', color: 'red' }}><span style={{ color: 'black', fontWeight: 'bolder' }}>___</span> Get in touch <span style={{ color: 'black', fontWeight: 'bolder' }}>___</span></p>
            </div>
            <div className="contact">
                <div className="leftContact">
                    <p style={{ fontSize: '30px', fontWeight: 600, marginBottom: '5px' }}>Get in touch</p>
                    <p >Email me with any questions or inquiries.I would be happy to answer your questions and set up a interaction with you.</p>
                    <div className="contactIcon">
                        <div className="contactElem">
                            <PersonIcon style={{ color: 'rgb(115, 1, 171)' }} classname="iconContact" />
                            <p>{ }{`   `} {` Dhruv Pandey`}  </p>
                        </div>
                        <div className="contactElem"  >
                            <HomeIcon style={{ color: 'rgb(115, 1, 171)' }} classname="iconContact" />
                            <p>Gurugram,India</p>
                        </div>
                        <div className="contactElem">
                            <EmailIcon style={{ color: 'rgb(115, 1, 171)' }} classname="iconContact" />
                            <p>dwebdeveloper14@gmail.com</p>
                        </div>
                        <div className="contactElem">
                            <PhoneIcon style={{ color: 'rgb(115, 1, 171)' }} classname="iconContact" />
                            <p>7217344995</p>
                        </div>
                    </div>

                </div>
                <div className="rightContact">
                    <form className="contact-form" onSubmit={sendEmail}>

                        <input required type="text" placeholder="Name" name="name" />
                        <input required type="email" placeholder="Email-Address" name="email" />
                        <input required type="text" placeholder="Subject" name="subject" />
                        <textarea required classname="textarea" placeholder="Your message" name="message" id="" cols="30" rows="4"></textarea>




                        <input type="submit" value="Send" />
                    </form>

                </div>
            </div>
        </div>
    )
}

export default Contact
