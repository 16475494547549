import React from 'react'
import './Home.css'
import { Link } from 'react-scroll'
import Typical from 'react-typical'

function Home() {
    return (
        <div className="homeContainer" id="home">
            <div className="homeElement">
                <p className="hello">Hello,my name is</p>
                <p className="name">Dhruv Pandey</p>
                <p className="type">And I'm a {`   `}
                    <Typical
                        className="typical"

                        loop={Infinity}
                        wrapper='b'
                        steps={
                            [
                                'Developer...',
                                1000,
                                'FreeLancer...',
                                1000
                            ]
                        }
                    />

                </p>
                <Link className="hireMe"
                    to="contact"
                    smooth={true}
                    duration={1000}
                >Hire Me</Link>
            </div>
        </div>
    )
}

export default Home
